import {IService} from "./interfaces/IService";
import {IFlightEngineApi} from "../flight-engine/models/manager-models/IFlightEngineApi";
import {IIoTService} from "../iot/iot-service/IIoTService";
import {IFlightEventService} from "./interfaces/IFlightEventService";
import {AircraftHealthEnum, AircraftIdentifier, LogLevel, PilotageStatus, FlightEventTarget} from "@qandq/cloud-gcs-core";
import {FlightEvent, FlightEventIoTMessage} from "./models/FlightEvent";
import {v4} from "uuid";
import {UserCredentials} from "../flight-engine/models/user-models/UserCredentials";
import {publishEvent, removeEvent, subscribeEvent} from "../../notification-locators/PubSubService";
import {EventForUI} from "../../notification-locators/events/EventForUI";
import {ServiceEvent} from "./events/ServiceEvent";
import {FlightEngineEvents} from "../flight-engine/FlightEngineEvents";
import {FlightEventType} from "../iot/iot-service/models/FlightEventType";
import {getAircraftName} from "../../utils/aircraftHelper";
import {HandOverInit} from "../iot/iot-service/models/HandOverInit";
import {HandOverResponse} from "../iot/iot-service/models/HandOverResponse";

export class FlightEventService implements IService, IFlightEventService {
    private readonly flightEngineApi: IFlightEngineApi;
    private readonly iotService: IIoTService;
    private readonly userCredentials: UserCredentials;

    constructor(flightEngineApi: IFlightEngineApi, iotService: IIoTService, userCredentials: UserCredentials) {
        this.flightEngineApi = flightEngineApi;
        this.iotService = iotService;
        this.userCredentials = userCredentials;

    }

    sendFlightEvent(aircraftIdentifier: AircraftIdentifier, source: string, flightEvent: FlightEvent): void {
        if(!aircraftIdentifier) {
            console.error("Aircraft Identifier not found")
            return;
        }

        const flightIdentifier = this.flightEngineApi.getFlightIdentifier(aircraftIdentifier.aircraftId);
        if(!flightIdentifier) {
            console.error("Flight Identifier not found")
            return;
        }

        // Any observers can not send Flight Events for aircrafts they are not controlling
        if(!this.flightEngineApi.isControllingAircraft(flightIdentifier.aircraftId) && source !== 'PS'){
            console.error("User is not controlling the aircraft")
            return
        }

        const flightEventIoT: FlightEventIoTMessage = {
            id: v4(),
            level: flightEvent.level,
            message: flightEvent.message,
            flightEventType: flightEvent.flightEventType,
            targets: flightEvent.targets,
            userCode: this.userCredentials.userCode,
            tenantCode: this.userCredentials.tenantCode,
            aircraftId: flightIdentifier.aircraftId,
            aircraftName: flightIdentifier.aircraftName,
            aircraftCertificateName: flightIdentifier.aircraftCertificateName,
            version: "1.0",
            flightId: flightIdentifier.flightId,
            source: source,
            isSimulator: false,
            timestamp: new Date().getTime(),
            environment: ""
        }

        this.iotService.publishFlightEvent(flightEventIoT)
    }

    start(): void {
        subscribeEvent(ServiceEvent.FlightEvent, this.onFlightEvent);
        subscribeEvent(FlightEngineEvents.FlightEvent, this.onFlightEvent);
        subscribeEvent(FlightEngineEvents.FlightEventReceived, this.onReceivedFlightEvent);

        subscribeEvent(ServiceEvent.ResponseReceivedStatus, this.onResponseReceived)
        subscribeEvent(ServiceEvent.ReceivedHandOverCommand, this.onReceiveHandOverRequest)
        subscribeEvent(ServiceEvent.ReceivedHandOverResponse, this.onReceiveHandOverResponse)
        subscribeEvent(FlightEngineEvents.AircraftPilotageStateChanged, this.onAircraftPilotageStateChanged)
        subscribeEvent(FlightEngineEvents.AircraftHealthChanged, this.onAircraftHealthChanged)
    }
    stop(): void {
        removeEvent(ServiceEvent.FlightEvent, this.onFlightEvent);
        removeEvent(FlightEngineEvents.FlightEvent, this.onFlightEvent);
        removeEvent(FlightEngineEvents.FlightEventReceived, this.onReceivedFlightEvent);

        removeEvent(ServiceEvent.ResponseReceivedStatus, this.onResponseReceived)
        removeEvent(ServiceEvent.ReceivedHandOverCommand, this.onReceiveHandOverRequest)
        removeEvent(ServiceEvent.ReceivedHandOverResponse, this.onReceiveHandOverResponse)
        removeEvent(FlightEngineEvents.AircraftPilotageStateChanged, this.onAircraftPilotageStateChanged)
        removeEvent(FlightEngineEvents.AircraftHealthChanged, this.onAircraftHealthChanged)
    }

    public onFlightEvent = (data: any[]) => {
        const log: FlightEvent = data[0];
        const identifier: AircraftIdentifier = data[1];

        this.sendFlightEvent(identifier, 'PS', log);
    };

    public onReceivedFlightEvent = (data: any[]) => {
        const event: FlightEvent = data[0];
        const identifier: AircraftIdentifier = data[1];

        if(event.targets?.includes(FlightEventTarget.Everyone)) {
            publishEvent(ServiceEvent.FlightEventReceivedForPlugin, event, identifier)
        }
        else if(event.targets?.includes(FlightEventTarget.PSPlugin)) {
            publishEvent(ServiceEvent.FlightEventReceivedForPlugin, event, identifier)
        }
    }

    private onResponseReceived = (data: any[]) => {
        const ackWithFlightData: any = data[0]

        if (!ackWithFlightData.ackReceived) {
            const event: FlightEvent = {
                level: LogLevel.Error,
                message: `Response not received for request id# ${ackWithFlightData.requestId}, for command ${ackWithFlightData.commandType}`,
                flightEventType: FlightEventType.Mission,
                targets: [FlightEventTarget.Everyone]
            }

            this.sendFlightEvent(ackWithFlightData.aircraftIdentifier, 'PS', event);
        }
    }


    private onAircraftHealthChanged = (data: any[]) => {
        const aircraftIdentifier: AircraftIdentifier = data[0]
        const oldHealth: AircraftHealthEnum = data[1]
        const newHealth: AircraftHealthEnum = data[2]

        const problems = [AircraftHealthEnum.Unhealthy, AircraftHealthEnum.DownLinkBroken, AircraftHealthEnum.Unattended]
        const isNewHealthProblematic = problems.indexOf(newHealth) > -1
        const isOldHealthProblematic = problems.indexOf(oldHealth) > -1

        if (isNewHealthProblematic) {
            const event: FlightEvent = {
                level: LogLevel.Warning,
                message: `Aircraft status changed from ${AircraftHealthEnum[oldHealth]} to ${AircraftHealthEnum[newHealth]}`,
                flightEventType: FlightEventType.System,
                targets: [FlightEventTarget.Everyone]
            }
            this.sendFlightEvent(aircraftIdentifier, 'PS', event);
        }
        if (isOldHealthProblematic && !isNewHealthProblematic) {
            const event: FlightEvent = {
                level: LogLevel.Warning,
                message: `Aircraft status changed from ${AircraftHealthEnum[oldHealth]} to ${AircraftHealthEnum[newHealth]}`,
                flightEventType: FlightEventType.System,
                targets: [FlightEventTarget.Everyone]
            }
            this.sendFlightEvent(aircraftIdentifier, 'PS', event);
        }
    }


    private onAircraftPilotageStateChanged = (data: any[]) => {
        const aircraftIdentifier = data[0]
        const oldStatus = data[1]
        const newStatus = data[2]
        if (newStatus === PilotageStatus.Observing) {
            const event: FlightEvent = {
                level: LogLevel.Info,
                message: "Started observing " + getAircraftName(aircraftIdentifier.aircraftName),
                flightEventType: FlightEventType.System,
                targets: [FlightEventTarget.Everyone]
            }

            this.sendFlightEvent(aircraftIdentifier, 'PS', event);
        }
    }

    private onReceiveHandOverRequest = (data: any) => {
        const request: HandOverInit = data[0]

        const event: FlightEvent = {
            level: LogLevel.Info,
            message: `Handover request for command ${request.commandType} received from ${request.userCode}`,
            flightEventType: FlightEventType.System,
            targets: [FlightEventTarget.Everyone]
        }

        this.sendFlightEvent(request as AircraftIdentifier, 'PS', event);
    };

    private onReceiveHandOverResponse = (data: any) => {
        const response: HandOverResponse = data[0]

        const event: FlightEvent = {
            level: LogLevel.Info,
            message: `Handover response ${response.data.answer} for command ${response.commandType} received from ${response.userCode}`,
            flightEventType: FlightEventType.System,
            targets: [FlightEventTarget.Everyone]
        }

        this.sendFlightEvent(response as AircraftIdentifier, 'PS', event);
    };
}