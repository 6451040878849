import {LogLevel, FlightEventTarget} from "@qandq/cloud-gcs-core";
import {FlightEventType} from "../../iot/iot-service/models/FlightEventType";
import {AircraftIoTHeader} from "../../iot/iot-service/models/AircraftIoTHeader";



export class FlightEventNoData {

}

export class FlightEventWithData<T> extends FlightEventNoData {
    data: T;
    dataType: string;

    constructor(data: T) {
        super();
        this.data = data;
        this.dataType = Object.getPrototypeOf(data).constructor.name;
    }
}

export interface FlightEvent {
    level: LogLevel;
    message: string;
    flightEventType: FlightEventType;
    flightData?: FlightEventNoData;
    targets: FlightEventTarget[];
}


export interface FlightEventIoTMessage extends FlightEvent, AircraftIoTHeader {
    readonly id: string;
}
