import {FlightEventMessage, IEventListener, PluginCallback} from "@qandq/cloud-gcs-core";
import {removeEvent, subscribeEvent} from "../notification-locators/PubSubService";
import {EventForUI} from "../notification-locators/events/EventForUI";
import {ICallbackService} from "../ps-infra/services/interfaces/ICallbackService";
import {FlightEventIoTMessage} from "../ps-infra/services/models/FlightEvent";

export class EventListener implements IEventListener, ICallbackService {
    private logoutCallbacks: PluginCallback[] = []

    onOnlineUsersCode(callback: any): void {
        subscribeEvent(EventForUI.OnlineUsers, callback);
    }

    onLogout(callback: PluginCallback): void {
        this.logoutCallbacks.push(callback)
    }

    removeLogout(callback: PluginCallback): void {
        this.logoutCallbacks = this.logoutCallbacks.filter(x => x.pluginName !== callback.pluginName)
    }

    getLogoutCallbacks(): PluginCallback[] {
        return this.logoutCallbacks
    }

    onActiveAircraftChanged(callback: any): void {
        subscribeEvent(EventForUI.ActiveAircraftChanged2, callback);
    }

    onActiveAircraftPilotageStateChanged(callback: any): void {
        subscribeEvent(EventForUI.ActiveAircraftPilotageStateChanged, callback);
    }

    onAircraftPilotChanged(callback: any): void {
        subscribeEvent(EventForUI.AircraftPilotChanged, callback);
    }

    onAnyAircraftStatusMessageReceived(callback: any): void {
        subscribeEvent(EventForUI.AnyAircraftStatusMessageReceived, callback);
    }

    onAnyUserStatusMessageReceived(callback: any): void {
        subscribeEvent(EventForUI.AnyUserStatusMessageReceived, callback);
    }

    onConnectionLoss(callback: any): void {
        subscribeEvent(EventForUI.ConnectionLoss, callback);
    }

    onFlyByWireControlsChanged(callback: any): void {
        subscribeEvent(EventForUI.FlyByWireControlsChanged, callback);
    }

    onInsertFlightEvent(callback: any): void {
        subscribeEvent(EventForUI.FlightEventReceivedForPlugin, (data: any[]) => {
            const event: FlightEventIoTMessage = data[0]
            const identifier = data[1]

            const message: FlightEventMessage = {
                level: event.level,
                message: event.message,
                flightData: event.flightData,
                timestamp: event.timestamp,
                id: event.id,
                source: event.source,
                userCode: event.userCode,
                tenantCode: event.tenantCode,
                aircraftId: event.aircraftId,
                aircraftName: event.aircraftName,
                aircraftCertificateName: event.aircraftCertificateName,
                version: event.version,
                flightId: event.flightId
            }

            callback && callback([message, identifier])
        });
    }

    removeInsertFlightEvent(callback: any): void {
        removeEvent(EventForUI.FlightEventReceivedForPlugin, callback);
    }

    onManageAircraftsEvent(callback: any): void {
        subscribeEvent(EventForUI.ManageAircraftsEvent, callback);
    }

    onMissionStarted(callback: any): void {
        subscribeEvent(EventForUI.MissionStarted, callback);
    }

    onReceivedHandOverCommand(callback: any): void {
        subscribeEvent(EventForUI.ReceivedHandOverCommand, callback);
    }

    onReceivedHandOverResponse(callback: any): void {
        subscribeEvent(EventForUI.ReceivedHandOverResponse, callback);
    }

    onReceivedHandoverPilotMessage(callback: any): void {
        subscribeEvent(EventForUI.ReceivedHandOverPilotMessage, callback);
    }

    onStatusIndicatorChanged(callback: any): void {
        subscribeEvent(EventForUI.StatusIndicatorChanged, callback);
    }

    onAircraftLocationChanged(callback: any): void {
        subscribeEvent(EventForUI.AircraftLocationChanged, callback);
    }

    onTelemetryDataChanged(callback: any): void {
        subscribeEvent(EventForUI.TelemetryDataChanged, callback);
    }

    onUnitTypeChanged(callback: any): void {
        subscribeEvent(EventForUI.ChangedUnitType, callback);
    }

    removeActiveAircraftChanged(callback: any): void {
        removeEvent(EventForUI.ActiveAircraftChanged, callback);
    }

    removeActiveAircraftPilotageStateChanged(callback: any): void {
        removeEvent(EventForUI.ActiveAircraftPilotageStateChanged, callback);
    }

    removeAircraftLocationChanged(callback: any): void {
        removeEvent(EventForUI.AircraftLocationChanged, callback);
    }

    removeAircraftPilotChanged(callback: any): void {
        removeEvent(EventForUI.AircraftPilotChanged, callback);
    }

    removeAnyAircraftStatusMessageReceived(callback: any): void {
        removeEvent(EventForUI.AnyAircraftStatusMessageReceived, callback);
    }

    removeAnyUserStatusMessageReceived(callback: any): void {
        removeEvent(EventForUI.AnyUserStatusMessageReceived, callback);
    }

    removeConnectionLoss(callback: any): void {
        removeEvent(EventForUI.ConnectionLoss, callback);
    }

    removeFlyByWireControlsChanged(callback: any): void {
        removeEvent(EventForUI.FlyByWireControlsChanged, callback);
    }

    removeInsertSummaryLog(callback: any): void {
        removeEvent(EventForUI.InsertSummaryLog, callback);
    }

    removeManageAircraftsEvent(callback: any): void {
        removeEvent(EventForUI.ManageAircraftsEvent, callback);
    }

    removeMissionStarted(callback: any): void {
        removeEvent(EventForUI.MissionStarted, callback);
    }

    removeReceivedHandOverCommand(callback: any): void {
        removeEvent(EventForUI.ReceivedHandOverCommand, callback);
    }

    removeReceivedHandOverResponse(callback: any): void {
        removeEvent(EventForUI.ReceivedHandOverResponse, callback);
    }

    removeStatusIndicatorChanged(callback: any): void {
        removeEvent(EventForUI.StatusIndicatorChanged, callback);
    }

    removeTelemetryDataChanged(callback: any): void {
        removeEvent(EventForUI.TelemetryDataChanged, callback);
    }

    onAircraftRemoved(callback: any): void {
        subscribeEvent(EventForUI.AircraftRemoved, callback)
    }

    removeAircraftRemoved(callback: any): void {
        removeEvent(EventForUI.AircraftRemoved, callback)
    }

    onAircraftLinkHealthChanged(callback: any): void {
        subscribeEvent(EventForUI.AircraftLinkHealthChanged, callback)
    }

    removeAircraftLinkHealthChanged(callback: any): void {
        removeEvent(EventForUI.AircraftLinkHealthChanged, callback)
    }

    onAircraftMissionReceived(callback: any): void {
        subscribeEvent(EventForUI.MissionReceived, callback)
    }

    removeAircraftMissionReceived(callback: any): void {
        removeEvent(EventForUI.MissionReceived, callback)
    }

    onAircraftPilotageStatusChanged(callback: any): void {
        subscribeEvent(EventForUI.AircraftPilotageStateChanged, callback)
    }

    removeAircraftPilotageStatusChanged(callback: any): void {
        removeEvent(EventForUI.AircraftPilotageStateChanged, callback)
    }

    removeHandoverPilotMessage(callback: any): void {
        removeEvent(EventForUI.ReceivedHandOverPilotMessage, callback)
    }

    removeOnlineUsersCode(callback: any): void {
        removeEvent(EventForUI.OnlineUsers, callback)
    }

    removeUnitTypeChanged(callback: any): void {
        removeEvent(EventForUI.ChangedUnitType, callback);
    }
}
