import { FC, useEffect, useMemo } from "react";
import "./index.scss";
import { BrowserView, MobileView } from "react-device-detect";
import { useLayout } from "../../UI/context/LayoutContext";
import { Skeleton } from "antd";

import PortraitModeModal from "../../views/components/PortraitModeModal";
import DockLayout from "../../UI/components/DockLayout";
import Header from "../../UI/components/Header";
import { LayoutManager } from "../../ps-infra/flight-engine/models/LayoutManager";
import { IPluginApi } from "@qandq/cloud-gcs-core";
import { PluginOutputDto } from "../../UI/model/IPlugin";
import { usePSInfraContext } from "../../UI/context/PSInfraContext";

interface MainProps {}

export interface IPluginHelper {
  layoutManager: LayoutManager;
  createPluginApi(
    pluginRootElement: HTMLElement,
    plugin: PluginOutputDto
  ): IPluginApi | undefined;
  isPluginLoading: boolean;
}

const Main: FC<MainProps> = () => {
  const { layoutModels } = useLayout();
  const { pluginHelper, initPSInfraContext } = usePSInfraContext();


  useEffect(() => {
    initPSInfraContext();
  }, [initPSInfraContext]);


  const GetLayout = useMemo(() => {
    if (layoutModels) {
      return (
        <DockLayout
          layoutData={layoutModels!.model}
          backgroundPlugins={layoutModels!.backgroundPlugins}
          pages={[...layoutModels!.pages.map((p) => typeof p === 'string' ? JSON.parse(p) : p)]}
          pluginHelper={pluginHelper}
        />
      );
    }
  }, [layoutModels, pluginHelper]);


  return (
    <>
      <Header></Header>
      {!layoutModels && !pluginHelper ? (
        <Skeleton active />
      ) : (
        <>
          <BrowserView>
            {GetLayout}
          </BrowserView>
          <MobileView>
            <>
              <PortraitModeModal></PortraitModeModal>
              {GetLayout}
            </>
          </MobileView>
        </>
      )}
    </>
  );
};

export default Main;
