
import {ICurrentUser} from "@qandq/cloud-gcs-core";
import httpFrappe from "../../../utils/requestFrappe";
import { IFrappeBaseResponse } from "./model/IFrappeBaseResponse";
import { PluginOutputDto } from "../../model/IPlugin";

export interface ICurrentUserExtended extends ICurrentUser {
  is_pilot_user: boolean;
}

export const getTenantSetting = (): Promise<IFrappeBaseResponse<ICurrentUserExtended>> => {
  var res = httpFrappe.get<null, IFrappeBaseResponse<ICurrentUserExtended>>(
    `/api/method/cloud_base_app.cloud_base_app.services.auth_service.get_user_details`
  );
  return res;
};

export const loginFrappe = async (usr: string, pwd: string): Promise<any> => {
  return httpFrappe.post(`/api/method/login`, {
    usr,
    pwd,
  });
};

export const getBoxLayout = async (isMobile: boolean): Promise<string> => {
  var res = await httpFrappe.get<null, IFrappeBaseResponse<string>>(
    `/api/method/pilot_station.pilot_station.doctype.layout_data.layout_data.get_granted_layout?isMobile=${isMobile}`,
  );
  return res.message;
};

export const logoutFrappe = async (): Promise<any> => {
  return httpFrappe.get(
    `/api/method/logout`,
    {},
    // {
    //   headers: {
    //     'X-Frappe-CSRF-Token': csrfToken,
    //   },
    // }
  );
};

export const getPluginInfo = async (pluginId: string): Promise<PluginOutputDto> => {
  const pluginName = pluginId.replace(" ", "%20");
  var res = await httpFrappe.get<null, IFrappeBaseResponse<PluginOutputDto>>(
    `/api/method/cloud_base_app.service_packages.services.plugin_api.get_plugin_data/`+pluginName
);
  return res.message;
};
