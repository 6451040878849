import { User } from "@qandq/cloud-gcs-core";

export class TopicHelper {
  public static getUserStatusPublishTopic(User: User): string {
    return `${User.tenantCode}/G/${User.userCode}/S`;
  }

  //TODO toUsercode  may remove
  public static getInterPilotSenderPublishTopic(
    User: User,
    toUserCode: string
  ): string {
    return `${User.tenantCode}/G/${User.userCode}/${toUserCode}/M`;
  }

  public static getAircraftAirTextPublishTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/G/${User.userCode}/${certificateName}/A`;
  }

  public static getAircraftCommandPublishTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/G/${User.userCode}/${certificateName}/C`;
  }

  public static getAllAircraftStatusesSubscribeTopic(
    User: User
  ): string {
    return `${User.tenantCode}/U/+/S`;
  }

  public static getAircraftStatusSubscribeTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/U/${certificateName}/S`;
  }

  public static getControlStationStatusSubscribeTopic(
    User: User
  ): string {
    return `${User.tenantCode}/G/+/S`;
  }

  public static getAircraftResponseSubscribeTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/U/${certificateName}/${User.userCode}/R`;
  }

  public static getAircraftTelemetryOldSubscribeTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/U/${certificateName}/T`;
  }

  public static getAircraftTelemetrySubscribeTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/U/${certificateName}/TD`;
  }

  public static getAircraftMissionSubscribeTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/U/${certificateName}/M`;
  }

  public static getAircraftPluginsSubscribeTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/C/${certificateName}/PL`;
  }

  public static getAircraftParametersSubscribeTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/U/${certificateName}/P`;
  }

  public static getInterPilotListenerSubscribeTopic(User: User): string {
    return `${User.tenantCode}/G/+/${User.userCode}/M`;
  }

  public static getFlightEventPublishTopic(
    certificateName: string,
    User: User
  ): string {
    return `${User.tenantCode}/U/${certificateName}/F`;
  }

  public static getPluginCommandPublishTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/G/${User.userCode}/${certificateName}/PCI`;
  }

  public static getPluginCommandResponseTopic(
    User: User,
    certificateName: string
  ): string {
    return `${User.tenantCode}/U/${certificateName}/${User.userCode}/PCR`;
  }

  public static getPluginDataAnalysisTopic(
    certificateName: string,
    User: User
  ): string {
    return `${User.tenantCode}/S/${certificateName}/DA`;
  }
}
