import React, { createContext, useContext } from "react";
import { PSInfraProvider } from "./PSInfraContext";
import { LayoutProvider } from "./LayoutContext";
import { PluginProvider } from "./PluginContext";
import { hasAccessToPilotStation } from "../../utils/userHelper";
import NonPilot from "../../pages/non-pilot/non-pilot";

export type ContainerContextProps = {};

let ContainerContext = createContext<any>({});

function ContainerProvider({ children }: { children: React.ReactNode }) {
  let value: ContainerContextProps = {};

  return (
    <ContainerContext.Provider value={value}>
      {hasAccessToPilotStation() ?
        <PluginProvider>
          <LayoutProvider>
            <PSInfraProvider>{children}</PSInfraProvider>
          </LayoutProvider>
        </PluginProvider>
        : <NonPilot />
      }
    </ContainerContext.Provider>
  );
}

const useContainer = (): ContainerContextProps => {
  return useContext<ContainerContextProps>(ContainerContext);
};

export { useContainer, ContainerProvider };
