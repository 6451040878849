import {IFlightEngineApi} from "./flight-engine/models/manager-models/IFlightEngineApi";
import {IIoTService} from "./iot/iot-service/IIoTService";
import FlightEngine from "./flight-engine/FlightEngine";
import {IoTServiceFactory} from "./iot/iot-service/IoTServiceFactory";
import {IInterPilotService} from "./services/interfaces/IInterPilotService";
import {InterPilotService} from "./services/InterPilotService";
import {IAirTextService} from "./services/interfaces/IAirTextService";
import {AirTextService} from "./services/AirTextService";
import {UserStatusService} from "./services/UserStatusService";
import {AircraftStatusListenerService} from "./services/AircraftStatusListenerService";
import {UserStatusPublisherService} from "./services/UserStatusPublisherService";
import {AircraftLinkHealthService} from "./services/AircraftLinkHealthService";
import {IAircraftLinkHealthService} from "./services/interfaces/IAircraftLinkHealthService";
import {removeEvent, subscribeEvent,} from "../notification-locators/PubSubService";
import {ServiceEvent} from "./services/events/ServiceEvent";
import {ConnectionStatus} from "./services/models/ConnectionStatus";
import {IUserStatusService} from "./services/interfaces/IUserStatusService";
import {IFlightEventService} from "./services/interfaces/IFlightEventService";
import {User} from "@qandq/cloud-gcs-core";
import {AircraftPluginCommandService} from "./services/AircraftPluginCommandService";
import {IAircraftPluginCommandService} from "./services/interfaces/IAircraftPluginCommandService";
import {FlightEventService} from "./services/FlightEventService";

export class PSInfra {
    private readonly flightEngine: FlightEngine;
    private readonly iotService: IIoTService;
    private readonly interPilotService: InterPilotService;
    private readonly airTextService: AirTextService;
    private readonly pluginCommandService: AircraftPluginCommandService;
    private readonly userStatusService: UserStatusService;
    private readonly aircraftStatusListenerService: AircraftStatusListenerService;
    private readonly userStatusPublisherService: UserStatusPublisherService;
    private readonly aircraftLinkHealthService: AircraftLinkHealthService;
    private readonly flightEventService: FlightEventService;
    private readonly onConnectionLoss: (e: Array<ConnectionStatus>) => {};
    private readonly user: User;

    constructor(
        user: User,
        onConnectionLoss: (e: Array<ConnectionStatus>) => {}
    ) {
        this.iotService = IoTServiceFactory.createIoTService(user);
        this.user = user;
        this.flightEngine = new FlightEngine(user, this.iotService);
        this.flightEventService = new FlightEventService(this.flightEngine, this.iotService, this.user);
        this.interPilotService = new InterPilotService(
            this.flightEngine,
            this.iotService
        );
        this.airTextService = new AirTextService(
            this.flightEngine,
            this.iotService
        );
        this.pluginCommandService = new AircraftPluginCommandService(
            this.flightEngine,
            this.iotService
        );
        this.userStatusService = new UserStatusService(
            this.iotService,
            this.flightEngine
        );
        this.aircraftStatusListenerService = new AircraftStatusListenerService(
            this.flightEngine,
            this.iotService
        );
        this.userStatusPublisherService = new UserStatusPublisherService(
            this.flightEngine,
            this.iotService
        );
        this.aircraftLinkHealthService = new AircraftLinkHealthService(
            this.flightEngine
        );

        this.onConnectionLoss = onConnectionLoss;
    }

    public async start() {
        await this.iotService.start();
        this.flightEngine.initializeMQTT();

        this.flightEventService.start();
        this.userStatusPublisherService.start();
        this.interPilotService.start();
        this.userStatusService.start();
        this.airTextService.start();
        this.pluginCommandService.start();
        this.aircraftStatusListenerService.start();
        this.aircraftLinkHealthService.start();

        subscribeEvent(ServiceEvent.OnIoTConnectionLoss, this.onConnectionLoss);
    }

    public finalize(): void {
        this.flightEventService.stop();
        this.userStatusPublisherService.stop();
        this.interPilotService.stop();
        this.userStatusService.stop();
        this.airTextService.stop();
        this.pluginCommandService.stop();
        this.aircraftStatusListenerService.stop();
        this.aircraftLinkHealthService.stop();

        IoTServiceFactory.finalizeIotService();
        this.flightEngine.finalize();
        this.iotService.finalize();

        removeEvent(ServiceEvent.OnIoTConnectionLoss, this.onConnectionLoss);
    }

    public getInterPilotService(): IInterPilotService {
        return this.interPilotService;
    }

    public getAirTextService(): IAirTextService {
        return this.airTextService;
    }

    public getAircraftPluginCommandService(): IAircraftPluginCommandService {
        return this.pluginCommandService;
    }

    public getFlightEngineApi(): IFlightEngineApi {
        return this.flightEngine;
    }

    public getUserStatusService(): IUserStatusService {
        return this.userStatusService;
    }

    public getAircraftLinkHealthService(): IAircraftLinkHealthService {
        return this.aircraftLinkHealthService;
    }

    public getFlightEventService(): IFlightEventService {
        return this.flightEventService;
    }
}
