import { createContext, useState, useContext, useEffect, useCallback } from "react";
import {
  getUserData,
  removeAccessToken,
  setAccessToken,
  setUserData,
} from "../../utils/tokenHelper";
import { IUserAuth } from "@qandq/cloud-gcs-core";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getTenantSetting,
  loginFrappe,
  logoutFrappe
} from "../services/frappe/frappeService";
import { ITokenAuthReq, ITokenAuthRes } from "../model/TokenAuth";
import { Authenticate } from "../services/tokenAuthServices";
import { getUserInfo } from "../services/sessionServices";
import { CurrentLoginInformationsOutputDto } from "../model/CurrentLoginInformationsOutputDto";
import { PermissionList } from "../../utils/permissionHelper";
import { setStorageAwsData } from "../../utils/awsTokenHelper";
import { isAppRunningInBox } from "../../utils/environmentHelper";
import LocalStorageUtil from "../../utils/localStorageUtil";
import { GetCookie } from "../../utils/cookieUtil";

export interface ILoginInput {
  username: string;
  password: string;
}

export interface AuthContextType {
  isAuth: boolean;
  forceLogout: boolean;
  logOut: () => void;
  logInWithBackOffice: (values: ITokenAuthReq) => void;
  logInWithFrappe: (input: ILoginInput) => Promise<any>;
  userInfo: IUserAuth | null;
  getUserDisplayName: () => string;
}

let AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuth, setAuth] = useState(!!getUserData());
  const [forceLogout, setForceLogout] = useState(false);

  let navigate = useNavigate();
  let [userInfo, setUserInfo] = useState<IUserAuth | null>(getUserData());

  const handleSetUser = (user: IUserAuth | null) => {
    setUserInfo(user);
    setUserData(user);
  };

  const getUserDetailsFromFrappe = useCallback(async () => {
      getTenantSetting().then((res) => {
        if (!res?.message?.username) {
          notification.error({
            message: "Username information cannot be empty.",
            description: "Please fill  username information at Tenant Office",
          });
  
          return;
        }
  
        setAuth(true);
        handleSetUser({
          userName: res.message.username,
          emailAddress: res.message.email,
          name: res.message.full_name,
          tenantCode: res.message.tenant_code,
          tenantName: res.message.tenant_name,
          permissionNames: res.message.is_pilot_user ? [PermissionList.Control_Real_Aircraft, PermissionList.Control_Simulator_Aircraft] : [],
        } as IUserAuth);
        navigate("/");
      });
    }, [navigate]);


  useEffect(() => {
    if (isAppRunningInBox()) {
      const interval = setInterval(() => {
        let user_id = GetCookie("user_id");
        if(isAuth && (user_id === "Guest" || user_id == null)) {
          setForceLogout(true);
        }
        if (!isAuth && user_id !== "Guest" && user_id != null) {
          getUserDetailsFromFrappe();
        }
      }, 1000); // Check every second
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [isAuth, forceLogout, getUserDetailsFromFrappe]);

  // login with Frappe
  const logInWithFrappe = async (input: ILoginInput) => {
    await loginFrappe(input.username, input.password)
      .catch((e: any) => {
        // console.log("error - loginFrappe", e);
        // todo: required to notify error. but why?
      })
      .then((loggedUser: any) => {
        if (loggedUser) {
          getUserDetailsFromFrappe();
        } else {
          setAuth(false);
          removeAccessToken();
          notification.error({
            message: "Invalid credentials!",
            description: "",
          });
        }
      });
  };

  // login with the box version and already logged in with frappe somewhere else


  // login on the non-box version
  const logInWithBackOffice = async (values: ITokenAuthReq) => {
    await Authenticate(values).then(async (res: ITokenAuthRes) => {
      setAccessToken(res.accessToken); //
      await getUserInfo().then((e: CurrentLoginInformationsOutputDto) => {
        if (
          !e.user.permissionNames.includes(
            PermissionList.Access_To_Pilot_Station
          )
        ) {
          notification.error({
            message: "You don't have permission to access pilot station!",
            description: "",
          });
          return;
        }
        if (e.cognitoData) {
          setStorageAwsData(
            e.cognitoData.authenticationResult,
            e.cognitoData.attributes
          );
        }

        setAuth(true);
        handleSetUser(e.user);
        navigate("/");
      });
    });
  };

  const logOut = async() => {

    if (isAppRunningInBox() && !forceLogout) {
      await logoutFrappe();      
    }
    handleSetUser(null);
    LocalStorageUtil.clearLocalStorage();
    setAuth(false);
    setForceLogout(false);
  };

  const getUserDisplayName = (): string => {
    const isInBox = isAppRunningInBox();
    if (isInBox) {
      return userInfo?.emailAddress ?? "Unknown User!!!";
    } else {
      return userInfo?.userName?.split("-")[0] ?? "Unknown User!!!";
    }
  };

  let value = {
    isAuth,
    forceLogout, 
    userInfo,
    logInWithBackOffice,
    logOut,
    logInWithFrappe,
    getUserDisplayName,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

export { useAuth, AuthProvider };
