import { useEffect } from 'react';
import { useAuth } from '../../UI/context/authContext';
import './non-pilot.scss';
import { Button } from 'antd';

const NonPilot = () => {
  const {forceLogout, logOut} = useAuth();

  useEffect(() => {
    if (forceLogout)
      logOut()
  }, [logOut, forceLogout]);

  return (
    <div className="non-pilot-container">
      <div className="non-pilot-message">
        You should have Pilot or Observer permission to access this page.
      </div>
      <Button className="non-pilot-button" onClick={() =>logOut()}>
        Log out
      </Button>
    </div>
  );
};

export default NonPilot;
