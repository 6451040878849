import {ServiceNotification} from "../../notification-locators/ServiceNotification";
import {FlightEngineNotification} from "../../notification-locators/FlightEngineNotification";
import {INotificationServiceLocator} from "../../notification-locators/INotificationServiceLocator";

export default class NotificationServiceLocator implements INotificationServiceLocator {
    private serviceNotification: ServiceNotification;
    private dataChangedNotification: FlightEngineNotification;

    constructor() {
        this.serviceNotification = new ServiceNotification();
        this.dataChangedNotification = new FlightEngineNotification();
    }

    getServiceNotification() {
        return this.serviceNotification
    }

    getDataChangedNotification() {
        return this.dataChangedNotification;
    }

    finalize() {
        this.serviceNotification.finalize();
        this.dataChangedNotification.finalize();
    }
}
