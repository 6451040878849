import { User } from "@qandq/cloud-gcs-core";
import { IUserAuth } from "@qandq/cloud-gcs-core";
import { PermissionList } from "./permissionHelper";
import {getToken, getUserData} from "./tokenHelper";
import jwt_decode from "jwt-decode";
import { isAppRunningInBox } from "./environmentHelper";

export interface MqttCredentials {
  mqttUsername: string;
  mqttPassword: string;
}

export const getMqttCredentials = (): MqttCredentials => {
  const isInBox = isAppRunningInBox();
  if (isInBox) {
    // TODO: will change after enabling ACL
    return {
      mqttUsername: "cloudgcs-ps",
      mqttPassword: "fc053db8-2e43-4438-9a77-ab851311f5fc",
    };
  }
  else {
    const token = getToken(); // Ensure getToken() is defined and imported
    const decoded = jwt_decode(token as string) as {
      mqttUsername: string;
      mqttPassword: string;
    };
  
    // Return the credentials
    return {
      mqttUsername: decoded.mqttUsername,
      mqttPassword: decoded.mqttPassword,
    };
  }
};

export const getUserCredentials: () => User = () => {
  let userData: IUserAuth | null = getUserData();

  if (!userData) {
    throw new Error("Empty User Data!");
  }

  return {
    ...userData,
    userCode: userData?.userName,
    isPilot: isPilot(),
  } as User;
};

export const isPilot = (): boolean => {
  let userData: IUserAuth | null = getUserData();

  if (!userData) {
    return false;
  }

  return (
    userData.permissionNames.includes(PermissionList.Control_Real_Aircraft) ||
    userData.permissionNames.includes(PermissionList.Control_Simulator_Aircraft)
  );  
};

export const hasAccessToPilotStation = (): boolean => {
  const isInBox = isAppRunningInBox();
  if (!isInBox) {
    return true;
  }
  return isPilot();
}

