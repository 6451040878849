import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Alert,
  Checkbox,
  message,
  Typography,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  KeyOutlined,
  CopyrightOutlined,
} from "@ant-design/icons";
import { ITenantOutputRes, ITenantsByUserReq, ITokenAuthReq } from "../../UI/model/TokenAuth";
import { useAuth } from "../../UI/context/authContext";
import { hideLoading, showLoading } from "../../UI/components/Loading";
import TenantSelect from "../../UI/components/TenantSelect";
import { getTenantsByUser } from "../../UI/services/tokenAuthServices";
import { isAppRunningInBox } from "../../utils/environmentHelper";

const { Title, Text } = Typography;

const Login = () => {
  const [form] = Form.useForm<ITokenAuthReq>();
  const [isError, setError] = useState<string | null>(null);
  const [showTenantSelect, setShowTenantSelect] = useState(false);
  const [tenants, setTenants] = useState<ITenantOutputRes[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageApi, contextHolder] = message.useMessage();
  const auth = useAuth();

  const toogleModal = () => {
    setShowTenantSelect(!showTenantSelect);
  };

  const handleLoginWithSingleTenant = (
    tenancyName: string,
    data: ITenantsByUserReq
  ) => {
    const formData = {
      tenancyName: tenancyName,
      userNameOrEmailAddress: data.emailAddress,
      password: data.password
    };
    showLoading();
    auth.logInWithBackOffice(formData);
  };

  const onFinish = async (values: ITokenAuthReq) => {
    showLoading();
    const isInBox = isAppRunningInBox();
    if (isInBox) {
      auth
      .logInWithFrappe({
        username: values.userNameOrEmailAddress,
        password: values.password,
      })
      .finally(() => {
        hideLoading();
      });
    }
    else {
      const data: ITenantsByUserReq = {
        emailAddress: values.userNameOrEmailAddress,
        password: values.password
      };

      await getTenantsByUser(data)
        .then(res => {
          console.log(res)
          hideLoading();
          setTenants(res);

          if (res.length === 1) {
            handleLoginWithSingleTenant(res[0].tenancyName, data);
          } else if (res.length > 1) {
            toogleModal();
          } else {
            // this for preventing the host entering ps
            notification.error({
              message: "Error",
              description: "Invalid credentials!"
            });
          }
        })
        .catch((err) => {
          // todo: required to notify error. but why?
        })
        .finally(() => {
          hideLoading();
        });
    }
  };

  return (
    <>
      {contextHolder}
      <div
        className="loginPageContainer"
        onClick={() => {
          setError(null);
        }}
      >
        <div className="">
          <Form
            name="basic"
            layout="horizontal"
            labelCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 22, offset: 2 },
            }}
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 20, offset: 2 },
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
            className={"loginForm"}
            colon={false}
            form={form}
          >
            <Title style={{ textAlign: "center" }} level={2}>
              Ground Control <br className="responsiveBr" />
              Pilot Station
            </Title>

            <Form.Item
              label="User Email"
              name="userNameOrEmailAddress"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter your email" },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Type your email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <Input.Password
                prefix={<KeyOutlined />}
                placeholder="Type your password"
              />
            </Form.Item>

            <Form.Item name="remember" wrapperCol={{ offset: 2, span: 0 }}>
              <Checkbox defaultChecked={false}>Remember me</Checkbox>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 2 },
              }}
            >
              <Button
                style={{
                  width: "100%",
                  borderRadius: "0.5rem",
                }}
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
            <Form.Item>
              <Link to="/users/forgotPassword">
                <a style={{ float: "right" }}>Forgot password?</a>
              </Link>
            </Form.Item>

            {isError && (
              <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
                <Alert message={isError} type="error" banner />
              </Form.Item>
            )}

            <Text className="copyright">
              <CopyrightOutlined /> {new Date().getFullYear()} CloudGCS
            </Text>
          </Form>
        </div>
      </div>
      { showTenantSelect && (
        <TenantSelect
          isOpen={showTenantSelect}
          tokenAuthModel={form.getFieldsValue()}
          tenants={tenants}
          onClose={() => toogleModal()}
          handleLogin={(req: ITokenAuthReq) => {
            showLoading();
            auth.logInWithBackOffice(req);
            hideLoading()
          }}
        />
      )}
    </>
  );
};

export default Login;
