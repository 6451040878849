import { AircraftState } from "./aircraft-models/AircraftState";
import {
  publishEvent,
  removeEvent,
  subscribeEvent,
} from "../../../notification-locators/PubSubService";
import AircraftFleet from "./AircraftFleet";
import { AircraftModel } from "./manager-models/AircraftModel";
import { AircraftCommandData } from "./broker-models/AircraftCommandTopicMessage";
import { FlightEngineEvents } from "../FlightEngineEvents";
import {
  AircraftIdentifier,
  AircraftPilotageStatus,
  AircraftTelemetry,
  CommandTypeEnum,
  LogLevel,
} from "@qandq/cloud-gcs-core";
import { LocalStorageJsonInterface } from "./LocalStorageJsonInterface";
import { SavedLocalStorage } from "./SavedLocalStorage";
import { IIoTService } from "../../iot/iot-service/IIoTService";
import {
  AircraftHealthEnum,
  AircraftLocation,
  IPluginDataAnalysis,
  PilotageStateEnum,
  TravelStatusEnum,
  User,
  FlightEventTarget
} from "@qandq/cloud-gcs-core";
import {FlightEvent, FlightEventNoData} from "../../services/models/FlightEvent";
import {FlightEventType} from "../../iot/iot-service/models/FlightEventType";
import {ServiceEvent} from "../../services/events/ServiceEvent";

export default class FlightData {
  userCredentials: User;

  private activeAircraft: AircraftState | null;
  private aircraftFleet: AircraftFleet;
  private iotService: IIoTService;

  constructor(user: User, iotService: IIoTService) {
    this.iotService = iotService;
    this.userCredentials = user;
    this.aircraftFleet = new AircraftFleet(user, this.iotService);
    this.activeAircraft = null;

    subscribeEvent(
      FlightEngineEvents.AircraftPilotageStateChanged,
      this.onAircraftPilotageStateChanged
    );
    subscribeEvent(
      FlightEngineEvents.AircraftHealthChanged,
      this.onAircraftHealthChanged
    );
  }

  /*
      setToUpLinkBroken = () => {
          const aircrafts = this.aircraftFleet.getAllRegisteredAircraft()
              .map(x => this.aircraftFleet.getAircraftByIdentifier(x))
          aircrafts.forEach(x => x?.setAircraftHealth(AircraftHealth.UpLinkBroken))
      }*/

  private onAircraftHealthChanged = (data: any[]) => {
    const aircraftIdentifier: AircraftIdentifier = data[0];
    if (this.activeAircraft?.aircraftId === aircraftIdentifier.aircraftId) {
      this.changeActiveAircraft(aircraftIdentifier.aircraftId);
    }
  };

  private onAircraftPilotageStateChanged = (data: any[]) => {
    const aircraftIdentifier = data[0];
    if (
      !this.activeAircraft ||
      this.activeAircraft!.aircraftId === aircraftIdentifier.aircraftId
    ) {
      this.changeActiveAircraft(aircraftIdentifier.aircraftId);
    }
    this.saveToLocalStorage();
  };

  getJsonForLocalStorage = () => {
    return JSON.stringify({
      userCode: this.userCredentials.userCode,
      controlledAircrafts: this.aircraftFleet
        .getListOfControllingAircrafts()
        .map((v) => v.aircraftCertificateName),
      observedAircrafts: this.aircraftFleet
        .getListOfObservingAircrafts()
        .map((v) => v.aircraftCertificateName),
      activeAircraftId: this.activeAircraft?.aircraftId,
      timestamp: new Date().getTime(),
    } as LocalStorageJsonInterface);
  };

  getPreviousFlightData(): SavedLocalStorage | null {
    let json = localStorage.getItem("flightdata");
    if (json === null) return null;
    let obj: LocalStorageJsonInterface = JSON.parse(json);
    if (
      this.userCredentials.userCode === obj.userCode &&
      new Date().getTime() - obj.timestamp < 15 * 60 * 1000
    ) {
      return {
        controlledAircrafts: obj.controlledAircrafts,
        observedAircrafts: obj.observedAircrafts,
        activeAircraftId: obj.activeAircraftId,
      } as SavedLocalStorage;
    }
    return null;
  }

  refreshAircraftPilotageState = () => {
    const isControlling = this.isActiveAircraftBeingControlled();
    publishEvent(
      FlightEngineEvents.ActiveAircraftPilotageStateChanged,
      isControlling
    );
  };

  subscribePluginDataAnalysis = (aircraftId: number) => {
    const aircraft = this.aircraftFleet.getAircraftById(aircraftId);

    aircraft?.subscribePluginDataAnalysis();
  };

  subscribeAircrafts = (pilotage: AircraftPilotageStatus[]) => {
    this.refreshAircraftPilotageState();
    publishEvent(FlightEngineEvents.ManageAircraftsEvent, ...pilotage);
  };

  changeActiveAircraft = (aircraftId: number) => {
    const aircraft = this.aircraftFleet.getAircraftById(aircraftId);

    if (aircraft !== null) {
      const isControlling = aircraft.isControlling();
      this.setActiveAircraft(
        new AircraftState(aircraft.aircraftIdentifier, isControlling)
      );
      this.refreshAircraftPilotageState();

      const data = {
        aircraftId,
        aircraft,
        aircraftParameters: aircraft.aircraftParameters,
      };
      // TODO: Remove ActiveAircraftChanged, bcs that event push all aircraftModel. Wrong!! Plugins use version 2
      publishEvent(FlightEngineEvents.ActiveAircraftChanged, data);
      publishEvent(
        FlightEngineEvents.ActiveAircraftChanged2,
        aircraft.aircraftIdentifier,
        aircraft.aircraftParameters
      );
    }
  };

  loadFromPreviousFlightData = () => {};

  checkActiveAircraftPilotageState = (
    aircraft: AircraftIdentifier,
    state: PilotageStateEnum
  ) => {
    if (
      this.activeAircraft != null &&
      this.activeAircraft.aircraftId === aircraft.aircraftId
    ) {
      this.activeAircraft.isControlling =
        state === PilotageStateEnum.Controlling;
      this.refreshAircraftPilotageState();
    }
  };

  isActiveAircraftBeingControlled = () => {
    return (
      (this.activeAircraft?.isControlling ?? false) &&
      (this.getActiveAircraftModel()?.aircraftHealth ===
        AircraftHealthEnum.Healthy ||
        this.getActiveAircraftModel()?.aircraftHealth ===
          AircraftHealthEnum.Start)
    );
  };

  getLastPluginDataAnalysis = (
    aircraftId: number
  ): IPluginDataAnalysis | null => {
    const aircraft = this.aircraftFleet.getAircraftById(aircraftId);
    if (aircraft) {
      return aircraft.getLastPluginDataAnalysis();
    }

    return null;
  };

  getLastAircraftTelemetry = (aircraftId: number): AircraftTelemetry | null => {
    const aircraft = this.aircraftFleet.getAircraftById(aircraftId);
    if (aircraft) {
      return aircraft.getLastAircraftTelemetry();
    }

    return null;
  };

  getLastAircraftLocation = (aircraftId: number): AircraftLocation | null => {
    const aircraft = this.aircraftFleet.getAircraftById(aircraftId);
    if (aircraft) {
      return aircraft.getLastAircraftLocation();
    }

    return null;
  };

  removeAircraft(aircraftCertificateName: string) {
    let aircraft = this.getAircraftByCertificateName(aircraftCertificateName);
    if (aircraft === null) return;
    this.aircraftFleet.remove(aircraftCertificateName);

    const event: FlightEvent = {
      flightEventType: FlightEventType.System,
      message: "Disconnected from " + aircraft.aircraftIdentifier.aircraftName,
      level: LogLevel.Info,
      flightData: new FlightEventNoData(),
      targets: [FlightEventTarget.Everyone]
    }

    publishEvent(FlightEngineEvents.FlightEvent, event, aircraft.aircraftIdentifier);

    this.saveToLocalStorage();
  }

  insertAircraft(identifier: AircraftIdentifier) {
    this.aircraftFleet.insert(identifier);
    this.saveToLocalStorage();
  }

  getControlledAircrafts(): AircraftIdentifier[] {
    return this.aircraftFleet.getListOfControllingAircrafts();
  }

  getObservedAircrafts(): AircraftIdentifier[] {
    return this.aircraftFleet.getListOfObservingAircrafts();
  }

  getActiveAircraft() {
    return this.activeAircraft;
  }

  setActiveAircraft(activeAircraft: AircraftState | null) {
    this.activeAircraft = activeAircraft;
    this.saveToLocalStorage();
  }

  getAircraftByCertificateName(aircraftCertificateName: string) {
    return this.aircraftFleet.getAircraftByCertificateName(
      aircraftCertificateName
    );
  }

  getAircraftById(aircraftId: number) {
    return this.aircraftFleet.getAircraftById(aircraftId);
  }

  saveToLocalStorage = () => {
    localStorage.setItem("flightdata", this.getJsonForLocalStorage());
  };

  executeCommandType = (commandType: CommandTypeEnum) => {
    const activeAircraftModel = this.getActiveAircraftModel();
    activeAircraftModel && activeAircraftModel.executeCommandType(commandType);
  };

  executeCommandWithData = (
    commandText: CommandTypeEnum,
    data: AircraftCommandData
  ) => {
    const activeAircraftModel = this.getActiveAircraftModel();
    activeAircraftModel &&
      activeAircraftModel.executeCommandWithData(commandText, data);
  };

  getActiveAircraftModel(): AircraftModel | null {
    if (this.activeAircraft) {
      return this.getAircraftByCertificateName(
        this.activeAircraft.aircraftCertificateName
      );
    }
    return null;
  }

  sendLogoutSignal() {
    this.getControlledAircrafts().forEach((it) => {
      const aircraft = this.getAircraftByCertificateName(
        it.aircraftCertificateName
      );
      aircraft?.executeCommand(CommandTypeEnum.Logout);
    });
  }

  hasAnyRegisteredAircraft = () => {
    return this.aircraftFleet.hasAircraft();
  };

  finalize() {
    removeEvent(
      FlightEngineEvents.AircraftPilotageStateChanged,
      this.onAircraftPilotageStateChanged
    );
    removeEvent(
      FlightEngineEvents.AircraftHealthChanged,
      this.onAircraftHealthChanged
    );
    this.aircraftFleet.finalize();
  }
}
