import {UserStatusMessageModel,} from "./models/UserStatusTopicMessage";
import {IIoTService} from "../iot/iot-service/IIoTService";
import {IService} from "./interfaces/IService";
import {IFlightEngineApi} from "../flight-engine/models/manager-models/IFlightEngineApi";
import {UserType} from "../flight-engine/models/user-models/UserType";
import {FlightIdentifier} from "@qandq/cloud-gcs-core";

export class UserStatusPublisherService implements IService {
    private readonly flightEngineApi: IFlightEngineApi;
    private readonly iotService: IIoTService;
    private interval: any;
    private worker: Worker | undefined
    private packageNumber: number;

    constructor(flightEngineApi: IFlightEngineApi, iotService: IIoTService) {
        this.flightEngineApi = flightEngineApi;
        this.iotService = iotService;
        this.packageNumber = 0;
    }

    sendStatus = async () => {
        this.packageNumber++;

        let message: UserStatusMessageModel = {
            listOfControllingAircrafts: this.flightEngineApi
                .getControlledAircrafts()
                .map((x) => {
                    return {
                        ...this.flightEngineApi.getFlightIdentifier(x.aircraftId),
                    } as FlightIdentifier;
                }),
            listOfObservingAircrafts: this.flightEngineApi
                .getObservedAircrafts()
                .map((x) => {
                    return {
                        ...this.flightEngineApi.getFlightIdentifier(x.aircraftId),
                    } as FlightIdentifier;
                }),
            userType: UserType.Pilot,
            packageNumber: this.packageNumber
        };

        this.flightEngineApi.setUserStatusPackage({
            packageNumber: this.packageNumber,
            timestamp: new Date().valueOf()
        })
        await this.iotService.publishUserStatus(message)
    }

    start = () => {
        // this.interval = setTimeout(this.sendStatus, 1000);
        this.worker = new Worker('/persistant-timer.js')
        this.worker.postMessage(1000)
        this.worker.onmessage = async (e) => {
            await this.sendStatus()
        }
    };

    stop = () => {
        clearInterval(this.interval);
        this.worker?.terminate()
    };
}
