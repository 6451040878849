export enum FlightEngineEvents {
    ActiveAircraftPilotageStateChanged = 'active-aircraft-pilotage-state-changed',
    AircraftPilotageStateChanged = 'aircraft-pilotage-state-changed',
    AircraftPilotChanged = 'aircraft-pilot-changed',
    AircraftHealthChanged = 'aircraft-status-changed',
    ActiveAircraftChanged = 'active-aircraft-changed',
    ActiveAircraftChanged2 = 'active-aircraft-changed2',
    ManageAircraftsEvent = 'manage-aircraft-events',
    MissionStarted = 'mission-started',
    MissionReceived = 'mission-received',
    TelemetryDataChanged = 'telemetry-data-changed',
    AircraftLocationChanged = 'aircraft-location-changed',
    AircraftRemoved = 'aircraft-removed',
    FlightEvent = 'flight-engine-flight-event',
    FlightEventReceived = 'flight-event-received',
}
