import {AircraftIdentifier, FlightEventMessage, FlightEventMessageInput, IFlightEventApi, FlightEventTarget} from "@qandq/cloud-gcs-core";
import {PluginOutputDto} from "../../../UI/model/IPlugin";
import {IFlightEventService} from "../../../ps-infra/services/interfaces/IFlightEventService";
import {FlightEvent, FlightEventWithData} from "../../../ps-infra/services/models/FlightEvent";
import {FlightEventType} from "../../../ps-infra/iot/iot-service/models/FlightEventType";
import {v4} from "uuid";

export class FlightEventApi implements IFlightEventApi {
  private pluginData: PluginOutputDto;
  private flightEventService: IFlightEventService;

  constructor(
      pluginData: PluginOutputDto,
      flightEventService: IFlightEventService
  ) {
    this.pluginData = pluginData;
    this.flightEventService = flightEventService
  }

  sendFlightEvent(identifier: AircraftIdentifier, log: FlightEventMessageInput): void {
    const event: FlightEvent = {
      level: log.level,
      message: log.message,
      flightEventType: FlightEventType.ServiceExtension,
      targets: log.targets ?? [],
      flightData: new FlightEventWithData(log.flightData)
    }

    const source = `PS:${this.pluginData.libraryName}@${this.pluginData.version}`;
    this.flightEventService.sendFlightEvent(identifier, source, event);
  }
}
