import { IFlightEngineForPluginApi } from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import {
  AircraftConfiguration,
  AircraftIdentifier,
  AircraftTelemetry,
  ISelectedAircraftApi,
  LogLevel,
} from "@qandq/cloud-gcs-core";
import { IAirTextService } from "../../../ps-infra/services/interfaces/IAirTextService";
import { CommandTypeEnum } from "@qandq/cloud-gcs-core";
import { PluginOutputDto } from "../../../UI/model/IPlugin";
import { AircraftLocation, AircraftPlugin, PluginData } from "@qandq/cloud-gcs-core";
import {IAircraftPluginCommandService} from "../../../ps-infra/services/interfaces/IAircraftPluginCommandService";

export class SelectedAircraftApi implements ISelectedAircraftApi {
  private flightEngine: IFlightEngineForPluginApi;
  private readonly airTextService: IAirTextService;
  private aircraftPluginCommandService: IAircraftPluginCommandService;
  private plugin: PluginOutputDto;

  constructor(
    flightEngine: IFlightEngineForPluginApi,
    plugin: PluginOutputDto,
    airTextService: IAirTextService,
    aircraftPluginCommandService: IAircraftPluginCommandService,
  ) {
    this.flightEngine = flightEngine;
    this.plugin = plugin;
    this.airTextService = airTextService;
    this.aircraftPluginCommandService = aircraftPluginCommandService;
  }

  getPluginConfig = (): any => {
    return this.plugin.config;
  };

  getAircraft3dModel = (): string => {
    const type = this.getAircraftType();
    let defModel = window.location.href + "models/default.glb";

    if (!type) return defModel;

    if (type?.includes("CGT")) {
      return window.location.href + `models/CGT.glb`;
    } else if (type?.includes("CNG")) {
      return window.location.href + `models/CGT.glb`;
    } else if (type?.includes("SIMULATOR")) {
      return window.location.href + `models/SIMULATOR.glb`;
    } else {
      return defModel;
    }
  };

  getAircraftConfiguration(): AircraftConfiguration | null {
    return this.flightEngine.getSelectedAircraftConfiguration();
  }

  getAircraftPlugins(): AircraftPlugin[] {
    if (this.flightEngine) return this.flightEngine.getActiveAircraftPlugins();

    return [];
  }

  getAircraftType(): string | null {
    return this.flightEngine.getSelectedAircraftType();
  }

  getLocation(): AircraftLocation | null {
    return this.flightEngine.getSelectedAircraftLocation();
  }

  getTelemetry(): AircraftTelemetry | null {
    return this.flightEngine.getSelectedAircraftTelemetry();
  }

  isBeingControlled(): boolean | null {
    return this.flightEngine.isActiveAircraftBeingControlled();
  }

  postAirText(data: any): void {
    const id = this.getAircraftIdentifier()?.aircraftId;

    if (id) {
      this.airTextService.postAirText(this.plugin.name, id, data);
    }
  }

  sendPluginCommand(data: PluginData): void {
    const identifier = this.getAircraftIdentifier();

    if (identifier) {
      this.aircraftPluginCommandService.sendPluginCommandWithResponse(identifier, data as PluginData)
    }
  }

  executeCommand(command: CommandTypeEnum, data: any) {
    const identifier = this.getAircraftIdentifier();

    if (identifier) {
      this.flightEngine.executeAircraftCommandWithData(
        identifier.aircraftId,
        command,
        data
      );
    }
  }

  getAircraftIdentifier(): AircraftIdentifier | null {
    return this.flightEngine.getActiveAircraftIdentifier();
  }

  isPluginSupported = (): boolean => {
    return (
      this.getAircraftPlugins().findIndex((x) => x.name === this.plugin.name && (x.jsFileUrl != null && x.jsFileUrl != "")) >
      -1
    );
  };
}
